(function ($) {
  "use strict";

const loader = document.getElementById('is-loader');
window.addEventListener('load', () => {
  loader.classList.add('fadeout');
  setTimeout(() => {
    loader.style.opacity = "0";
    loader.style.pointerEvents = "none";
  }, 100);
}, false);


window.onload = function() {
  scroll_effect();

  $(window).scroll(function(){
    scroll_effect();
  });

  function scroll_effect(){
    $('.is-effect-fade').each(function(){
      var elemPos = $(this).offset().top;
      var scroll = $(window).scrollTop();
      var windowHeight = $(window).height();
      if (scroll > elemPos - windowHeight){
      $(this).addClass('is-effect-fade__animation');
      }
    });
  }
};

$(function() {

  //ページ内スクロール
  $('a[href^="#"]').on('click', function() {
    var speed = 300;
    var href = $(this).attr("href");
    var target = $(href == "#" || href == "" ? 'html' : href);
    var position = target.offset().top;
    $("html, body").animate({
      scrollTop: position
    }, speed, "swing");
    return false;
  });

  //ページ上部へ戻る
  var backToTop = $('#is-page-top');
  backToTop.hide();
  $(window).scroll(function() {
    if ($(this).scrollTop() > 50) {
      backToTop.fadeIn();
    } else {
      backToTop.fadeOut();
    }
  });
  $('#is-page-top').on('click', function() {
    $('body,html').animate({
      scrollTop: 0
    }, 300);
    return false;
  });

});


}(jQuery));